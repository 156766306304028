.bookcard {
    background-color: rgb(228, 215, 240);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 250px;
    width: 16vw;
    border-radius: 20px;
    padding: 2rem 0;
}

.bookcard img {
    width: 15vw;
    max-width: 20rem;
    min-width: 10rem;
    border-radius: 20px;
}

@media screen and (max-width: 700px) {
    .bookcard {
        width: 40vw;
    }
}
@media screen and (max-width: 450px) {
    .bookcard {
        width: 90vw;
        min-width: 40vw;
    }
}
