.hidden {
    display: none;
}

.error {
    display: block;
    color: red;
}

.input-error {
    border-color: red;
    background: #ffe9e9;
}

.form-container {
    background-color: #fffbdb;
    border: 1px solid rgba(213, 203, 91, 0.8);
    border-radius: 15px;
    width: 80%;
    margin: 0 auto;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.claim-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    justify-content: center;
    align-items: center;
}

.claim-form label {
    margin-right: 15px;
}

.submit-button {
    width: 25%;
    border-radius: 7.5px;
    background-color: rgb(241, 229, 168);
    border: 1px solid #6b675e2b;
    padding: 0.3rem;
}

input {
    border-radius: 7.5px;
    border: 1px solid #6b675e2b;
    padding: 0.3rem;
    font-size: larger;
}

label {
    font-size: larger;
}
