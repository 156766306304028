.book-details {
    display: flex;
    max-width: 1200px;
    margin: 3rem auto;
    gap: 3rem;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.book-image img {
    width: 40vw;
}

@media screen and (max-width: 450px) {
    .book-details {
        flex-direction: column;
        width: 80vw;
        text-align: center;
    }
    .book-image {
        margin: 0 auto;
    }

    .book-image img {
        width: 80vw;
    }
}
