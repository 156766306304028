nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(214, 166, 32);
}

nav a {
    text-decoration: none;
    color: black;
}

.active {
    color: blueviolet;
}

.links {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    font-size: x-large;
}

.links a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 450px) {
    nav {
        flex-direction: column;
        align-items: center;
    }
}
